@font-face {
    font-family: Lemon-Tuesday;
    src: url("/assets/fonts/Lemon Tuesday.otf") format("opentype");
}

@font-face {
    font-family: HKGrotesk;
    src: url("/assets/fonts/HKGrotesk-Light.otf") format("opentype");
}

@font-face {
  font-family: GeneralSans;
  src: url("/assets/fonts/GeneralSans-Regular.otf") format("opentype");
}

@font-face {
  font-family: GeneralSans-Medium;
  src: url("/assets/fonts/GeneralSans-Medium.otf") format("opentype");
}

@font-face {
  font-family: GeneralSans-Light;
  src: url("/assets/fonts/GeneralSans-Light.otf") format("opentype");
}

@font-face {
  font-family: GeneralSans-Semibold;
  src: url("/assets/fonts/GeneralSans-Semibold.otf") format("opentype");
}

body {
  margin: 0;
  font-family: "GeneralSans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: #1A3C42;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  animation: fadein 2s;
      -moz-animation: fadein 2s; /* Firefox */
      -webkit-animation: fadein 2s; /* Safari and Chrome */
      -o-animation: fadein 2s; /* Opera */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.curly-font {
    font-family: 'Lemon-Tuesday', sans-serif;
    text-transform: none;
    font-weight: 400 !important;
}
