.session {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    border-radius: 7px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 20px -10px rgba(0,0,0,0.25);
    margin: 30px 0;
    padding: 32px 40px;
    transition: .3s ease;

    @media (max-width: 768px) {
        position: relative;
        padding: 15px 20px;
        margin: 0 0 30px;
    }

    .time {
        margin-right: 15px;

        h3 {
            color: #1A3C42;
            font-family: "GeneralSans-Medium";
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;

            @media (max-width: 768px) {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }

    .details {
        margin: 0 15px;
        max-width: calc(100% - 260px);

        @media (max-width: 768px) {
            margin: 5px 0 0;
            max-width: 100%;
        }
    }

    h3 {
        margin: 0;
        font-family: "GeneralSans-Semibold";
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 27px;

        @media (max-width: 768px) {
            font-size: 18px;
            line-height: 24px;
        }
    }

    .description {
        p {
            margin: 15px 0 10px;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 18px;

            @media (max-width: 768px) {
                margin: 5px 0;
            }
        }
    }

    .readmore {
        margin-left: auto;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-end;
        gap: 15px;

        @media (max-width: 768px) {
            max-width: 100%;
            margin: 0;
        }

        p {
            // opacity: 0;
            // transition: .3s ease;
            white-space: nowrap;
            margin: 0;
            color: #65184F;
            font-family: "GeneralSans-Semibold";
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 18px;
            display: flex;
            align-items: center;
            cursor: pointer;

            img {
                height: 15px;
                width: 15px;
                margin-right: 8px;
                transform: rotate(180deg);
            }
        }

        .arrowIcon-active {
            transform: rotate(-90deg);
        }
    }

    .category {
        height: 14px;
        width: 14px;
        box-sizing: border-box;
        margin: 0 5px;
        display: inline-flex;

        &:first-child {
            margin: 0 5px 0 15px;
        }

        &.legal {
            background-color: #238CDD;
        }

        &.recruiting {
            background-color: #D66160;
        }

        &.founders {
            background-color: #C78BBC;
        }

        &.accelerators {
            background-color: #68B76B
        }

        &.sales {
            background-color: #e0d17b;
        }

        &.funding {
            background-color: #C48C7C;
        }

        &.office {
            background-color: #ADADAD;
        }

        &.market {
            background-color: #735da5;
        }

        @media (max-width: 768px) {
            height: 14px;
            width: 14px;
            margin: 0 5px;
        }
    }

    .location {
        margin-top: 5px;
        display: flex;
        align-items: center;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px;

        svg {
            height: 20px;
            width: 20px;
            margin-right: 8px;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .videolink {
        margin-top: 15px;
        display: flex;
        flex-direction: row;

        svg {
            height: 20px;
            width: 20px;
            margin-right: 5px;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .speakers {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px;

        b {
            font-family: "GeneralSans-Semibold";
        }

        @media (max-width: 768px) {
            margin: 0 0 10px;
        }
    }

    @media (max-width: 500px) {
        flex-direction: column;
    }

    .react-add-to-calendar {
        display: inline-block;
        cursor: pointer;

        &__wrapper {
            display: none;
        }

        @media (max-width: 768px) {
            position: absolute;
            top: 15px;
        }

        &__button {
            pointer-events: all;
            position: relative;
            height: 20px;
            width: 20px;

            &:before {
                content: '';
                background-image: url('/assets/images/Icon-add-cal.svg');
                height: 20px;
                width: 20px;
                display: block;
                background-size: cover;
                margin-left: auto;
            }
        }

        &__dropdown {
            // position: absolute;
            // background-color: #FFF;
            // padding: 60px;
            // left: 50%;
            // top: 50%;

            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                text-align: right;
                display: flex;

                li {
                    padding: 0;
                }

                li a {
                    display: block;
                    pointer-events: all;

                    &:after {
                        content: '';
                        height: 20px;
                        width: 20px;
                        display: block;
                        background-size: cover;
                        margin-left: auto;
                    }

                    &.apple-link {
                        &:after {
                            background-image: url('/assets/images/apple.svg');
                        }
                    }

                    &.google-link {
                        &:after {
                            background-image: url('/assets/images/google.svg');
                        }
                    }

                    &.outlook-link {
                        &:after {
                            background-image: url('/assets/images/outlook.svg');
                        }
                    }
                }
            }
        }

    }
}
