.home {
    // height: calc(100vh - 100px);
    width: 100vw;
    padding-top: 100px;

    @media (max-width: 768px) {
        padding-top: 28px;
        text-align: left;
    }

    h1 {
        margin-bottom: 30px;

        @media (max-width: 768px) {
            padding: 0 20px;
        }
    }

    &-programs {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        padding: 0 60px;
        justify-content: center;
        align-items: center;
        gap: 20px;

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
            padding: 0 20px;
        }
    }

    // .playbook {
    //     padding: 40px 50px;
    //     color: #FFF;
    //     text-decoration: none;
    //     border: 1px solid #FFF;
    // }
}
