.categories {
    display: flex;
    // justify-content: space-between;
    list-style: none;
    padding: 0;
    gap: 15px;
    hyphens: auto;
    word-wrap: break-word;
    overflow-wrap: break-word;
    position: sticky;
    top: 80px;
    background-color: #f7f7fa;
    margin: 0;
    padding: 16px 0;
    z-index: 1;

    @media (max-width: 768px) {
        overflow: scroll;
        padding: 17px 20px;
        white-space: nowrap;
        border-top: 1px solid rgba(26, 60, 66, 0.15);
        border-bottom: 1px solid rgba(26, 60, 66, 0.15);
        top: 100px;
    }

    .category {
        // max-width: 127.5px;
        text-align: left;
        font-family: "GeneralSans-Semibold";
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 16px;
        padding-left: 15.5px;
        

        &:before {
            content: "";
            height: 9px;
            width: 9px;
            display: inline-block; 
            margin-right: 6.5px; 
            margin-left: -15.5px;
        }
        
        &.legal {
            &:before {
                background-color: #238CDD;
            }
        }

        &.recruiting {
            &:before {
                background-color: #D66160;
            }
        }

        &.founders {
            &:before {
                background-color: #C78BBC;
            }
        }

        &.accelerators {
            &:before {
                background-color: #68B76B
            }
        }

        &.sales {
            &:before {
                background-color: #e0d17b;
            }
        }

        &.funding {
            &:before {
                background-color: #C48C7C;
            }
        }

        &.office {
            &:before {
                background-color: #ADADAD;
            }
        }

        &.market {
            &:before {
                background-color: #735da5;
            }
        }
    }
}

// .categories {
//     display: flex;
//     max-width: 1200px;
//     width: calc(100% - 120px) auto;
//     justify-content: center;


//     .category {
//         width: 14.28%;
//         height: 40px;
//         margin: 0 15px;
//         position: relative;

//         p {
//             font-weight: bold;
//             font-size: 12px;
//             color: #FFF;
//             // transform: translateY(-45px);
//         }

//         @media (max-width: 500px) {
//             width: 100px;
//         }
//     }

//     @media (max-width: 500px) {
//         flex-direction: column;
//         height: 100%;
//         justify-content: space-between;
//         left: 0;
//         top: 0;
//         background-color: #242424;
//         position: fixed;
//         width: 130px;
//         z-index: 999;
//         transform: translateX(-100%);
//         transition: 500ms ease;
//         overflow: scroll;
//         padding-top: 75px;

//         &:after {
//             content: '';
//             height: 120%;
//             width: 100%;
//             background-color: #242424;
//             position: absolute;
//             top: -10%;
//         }

//         .category {
//             z-index: 9999;
//         }

//         &.keyIsActive {
//             transform: translateX(0);
//         }
//     }
// }
