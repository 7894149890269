.cityCard {
    text-decoration: none;
    
    &:hover {
        cursor: pointer;
    }
    
    &-cityCardImage {
        height: 356px;
        position: relative;
    
        @media (max-width: 768px) {
            height: 125px;
            width: 100%;
            border-radius: 10px;
        }
    
        &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            height: 100%;
            width: 100%;
            background: linear-gradient(0deg, rgba(3, 34, 54, 0.25), rgba(3, 34, 54, 0.25));
            z-index: 1;
    
            @media (max-width: 768px) {
                border-radius: 10px;
            }
        }    
    }

    &-cityCardImageImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        transition: opacity 300ms ease-out;
        
        

        @media (max-width: 768px) {
            border-radius: 10px;
        }
    }
    
    &-cityCardCity {
        font-family: GeneralSans-Light;
        font-size: 33px;
        font-weight: 300;
        line-height: 31px;
        color: #FFFFFF;
        position: absolute;
        bottom: 14px;
        left: 24px;
        margin: 0;
        display: flex;
        align-items: center;
        z-index: 2;

        img {
            height: 49px;
            width: 49px;
            margin-left: 5px;
        }
    
        @media (max-width: 768px) {
            left: 14px;
        }
    }
    
    &-cityCardDate {
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.02em;
        margin: 10px 0 0;
        text-align: left;
    
        span {
            color: #65184F;
            font-weight: 600;
        }
    }
}