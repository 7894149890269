.Header {
    display: flex;
    padding: 0 60px;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        padding: 0;
        position: relative;
    }

    &-left,
    &-right {
        width: 50%;

        @media (max-width: 768px) {
            width: auto;
        }
    }

    &-left {
        text-align: left;
        padding-top: 35px;

        @media (max-width: 768px) {
            padding-top: 10px;
        }

        .additionalLogos {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            margin-top: 20px;

            @media (max-width: 768px) {
                padding-left: 20px;
            }

            img {
                max-width: 120px;
                height: auto;
                object-fit: contain;
            }
        }

        a {
            @media (max-width: 768px) {
                position: absolute;
                top: 50px;
                left: 42px;
                z-index: 1;
            }
        }

        a img {
            transform: rotate(90deg);

            @media (max-width: 768px) {
                filter: brightness(0) invert(1);
            }
        }

        h1 {
            font-size: 72px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 97px;
            // margin-top: 84px;
            margin-bottom: 24px;

            @media (max-width: 768px) {
                margin: 0;
                font-size: 42px;
                line-height: 52px;
                padding-left: 20px;
                padding-top: 10px;
            }
        }

        .ProgramDate {
            font-size: 20px;
            letter-spacing: 0;
            line-height: 27px;

            @media (max-width: 768px) {
                font-size: 15px;
                line-height: 21px;
                padding: 0 20px;
            }
        }
    }

    &-right {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding-left: 40px;
        padding-top: 40px;

        @media (max-width: 768px) {
            padding-left: 20px;
            padding-top: 30px;
        }

        img {
            min-height: 435px;
            height: 100%;
            width: 100%;
            object-fit: cover;
            box-shadow: -40px -40px #65184F;

            @media (max-width: 768px) {
                height: 345px;
                filter: brightness(0.8);
                box-shadow: -20px -30px #65184F;
            }
        }
    }
    // height: 500px;
    // width: 100vw;
    // background-size: cover;
    // background-position: center;
    // position: relative;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // flex-direction: column;
    // color: #FFF;

    // &:after {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     height: 100%;
    //     width: 100%;
    //     background-color: rgba(0, 0, 0, 0.45);
    // }

    // h1 {
    //     margin-left: 60px;
    //     color: #FFF;
    //     z-index: 1;
    //     font-size: 6em;
    //     margin: 0;

    //     @media (max-width: 500px) {
    //         font-size: 5em;
    //     }
    // }

    // .date {
    //     z-index: 1;
    //     font-size: 1.5em;
    //     font-weight: 700;
    //     text-transform: uppercase;
    //     color: #FFF;
    // }
}
