

.content {
    max-width: 1200px;
    margin: auto;
    padding: 76px 60px;

    @media (max-width: 768px) {
        padding: 0;
    }
}
.tabs {
    display: flex;
    justify-content: center;
    gap: 80px;
    padding: 12px 0;
    margin-bottom: 57px;
    position: sticky;
    top: 0;
    background-color: #f7f7fa;
    z-index: 2;
    
    @media (max-width: 768px) {
        gap: 25px;
        overflow: scroll;
        padding: 30px 20px;
        margin-bottom: 28px;
        justify-content: flex-start;
    }

    .tabItem {
        display: flex;
        flex-direction: column;
        background-color: transparent;
        border: none;
        color: #1A3C42;
        text-align: left;
        font-size: 28px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 38px;
        position: relative;
        transition: 300ms ease;
        opacity: 0.3;

        @media (max-width: 768px) {
            font-size: 22px;
            line-height: 29px;
            white-space: nowrap;
        }


        &:after {
            content: '';
            height: 80px;
            width: 143px;
            position: absolute;
            top: -6px;
            left: -10px;
            background-image: url('/assets/images/circle_doodle.svg');
            background-repeat: no-repeat;
            background-size: contain;
            z-index: -1;
            opacity: 0;
            transition: 300ms ease;
        }


        p {
            font-size: 12px;
            letter-spacing: 0;
            line-height: 16px;
            margin: 0;
            font-family: 'GeneralSans-Semibold';

            @media (max-width: 768px) {
                font-size: 10px;
                line-height: 13px;
            }
        }

        &:focus {
            outline: none;
        }

        &:hover {
            cursor: pointer;
            &:after {
                content: '';
                height: 80px;
                width: 143px;
                position: absolute;
                top: -6px;
                left: -10px;
                background-image: url('/assets/images/circle_doodle.svg');
                background-repeat: no-repeat;
                background-size: contain;
                z-index: -1;
                opacity: 1;
                
            }
        }

        &-isActive {
            opacity: 1;
            &:after {
                content: '';
                height: 80px;
                width: 143px;
                position: absolute;
                top: -6px;
                left: -10px;
                background-image: url('/assets/images/circle_doodle.svg');
                background-repeat: no-repeat;
                background-size: contain;
                z-index: -1;
                opacity: 1;
            }
        }

        &.contacts {
        }

    }
}

.keyButton {
    display: none;
    background-color: rgba(0,0,0,0);
    border: none;
    padding: 15px 0 15px 15px;
    transition: 500ms ease;
    position: sticky;
    top: 92px;
    width: 50px;

    @media (max-width: 500px) {
        display: flex;
        margin-left: auto;
    }

    &-isActive {
        transform: rotate(90deg);
    }

    &:focus {
        outline: none;
    }
}
