.AddToCalendar {
    display: flex;

    img {
        height: 20px;
        width: 20px;
    }

    button {
        padding: 0;
        background-color: transparent;
        border: none;
        margin-left: 15px;
    }

    div a {
        padding: 0 7.5px;
    }
}