
$blue: #2C5BDC;
$dark: #242424;
$red: #EF5748;
$grey: #E9E9E9;

* {
  font-family: 'GeneralSans', 'Roboto', sans-serif;
  color: #1b3c42;
}

html, body {
  height: 100%;
  text-align: center;
  scroll-behavior: smooth;
}

body {
    background-color: #F7F7FA;
    position: relative;

    &.no-scroll {
        overflow: hidden;
    }
}

p {
  font-family: 'GeneralSans';
  font-weight: 300;
  font-size: 1.1rem;
}

h1, h2, h3, h4, h5 {
    font-family: 'GeneralSans';
}

.authContainer {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    background-color: #24242480;
    z-index: 2;
    transition: all ease 200ms;
    
    .authContent {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        max-width: 75%;
        max-height: 100vh;
        overflow: scroll;
        margin: auto;
        position: relative;

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
            max-width: 90%;
        }
    }

    &-logo {
        height: 25px;
        width: 132px;
        margin-bottom: 40px;
    }

    &-left {
        text-align: left;
        padding: 60px;
        background-color: #F7F7FA;

        @media (max-width: 768px) {
            padding: 30px;
        }

        h1 {
            color: #1A3C42;
            font-size: 24px;
            letter-spacing: 0;
            line-height: 32px;
            font-weight: 300;
            margin-bottom: 40px;

            strong {
                color: #65184F;
            }
        }

        p {
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 18px;
        }
    }

    &-email,
    &-password,
    &-name {
        width: 100%;
        border: 0;
        border-bottom: 1px solid #1A3C42;
        padding-bottom: 4px;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px;
        outline: none;
        background-color: #F7F7FA;

        &::placeholder {
            color: #72838D;
        }
    }

    &-password,
    &-name {
        margin-top: 46px;
    }

    &-signInActions {
        text-align: center;
    }

    &-signInButton {
        background-color: #1A3C42;
        border: none;
        border-radius: 24px;
        display: flex;
        min-width: 221px;
        height: 48px;
        margin: 30px auto;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18px;
        cursor: pointer;

        &_email {
            color: #FFF;

            &:disabled {
                background-color: #72838D;
                cursor: not-allowed;
            }
        }

        &_google {
            color: #1A3C42;
            border: 1px solid #1A3C42;
            background-color: #F7F7FA;
            justify-content: space-between;
            padding: 12px 32px;

            img {
                height: 22px;
                width: 22px;
            }
        }

        &_button {
            color: #FFF;
        }
    }

    &-close {
        background-color: transparent;
        color: #fff;
        position: absolute;
        right: 20px;
        top: 20px;
        border: none;

        @media (max-width: 768px) {
            color: #000;
            font-size: 20px;
        }
    }

    &-right {
        background-color: #1A3C42;
        padding: 60px;
        position: relative;

        @media (max-width: 768px) {
            display: none;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-resetPassword {
        border: none;
        background-color: transparent;
        text-decoration: underline;
        color: #65184F;
        cursor: pointer;

        &Container {
            padding: 20px 0;
        }
    }
}

.container {
    padding-bottom: 60px;
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
 .stage {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 32px 0;
    padding: 0 5%;
    overflow: hidden;
 }

 .dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #65184F;
    color: #65184F;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
  .dot-flashing::before, .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #65184F;
    color: #65184F;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  .dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #65184F;
    color: #65184F;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }
  
  @keyframes dot-flashing {
    0% {
      background-color: #65184f;
    }
    50%, 100% {
      background-color: rgba(101, 24, 79, 0.2);
    }
  }