.sessionDetails {
    transition: all 0.5s ease;
    max-height: 0;
    overflow: hidden;
    width: 100%;
    padding-left: 78px;
    opacity: 0;

    @media (max-width: 768px) {
        padding-left: 0;
    }
    
    &-isActive {
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px solid rgba(26,60,66,0.15);
        opacity: 1;
    }

    h4 {
        font-family: 'GeneralSans-Semibold';
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 18px;
        margin-top: 0;
    }

    .speakers {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;

        .speaker {
            // margin: 0 30px;
            text-align: center;
            display: flex;
            max-width: calc(33% - 60px);
            align-items: center;

            @media (max-width: 768px) {
                max-width: 100%;
            }

            &-name {
                font-family: 'GeneralSans-Semibold';
                display: flex;
                align-items: center;
            }

            p {
                font-size: 14px;
                letter-spacing: 0;
                line-height: 18px;
                margin: 0;
                text-align: left;
            }

            .linkedin {
                svg {
                    margin-left: 5px;
                    width: 16px;
                    height: 16px;
                }
            }

            img {
                height: 64px;
                width: 64px;
                border-radius: 50%;
                object-fit: cover;
                margin-right: 10px;
            }
        }

        
    }

    @media (max-width: 768px) {
       
    }
}
