.admin {
    margin-top: 100px;
    content-visibility: auto;
    
    .user {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 15px;


        &:nth-child(even) {
            background-color: #DDD;
        }
    }

    .roles {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}