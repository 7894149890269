nav {
    z-index: 1;
    width: 100vw;
    padding: 0 60px;
    box-sizing: border-box;
    z-index: 998;

    @media (max-width: 768px) {
        padding: 0 20px;
    }

    .navbar {
        &-container {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #D8D8D8;
            padding: 35px 0;

            button {
                background: none;
                border: none;
                cursor: pointer;
                
                img {
                    height: 22px;
                    width: 23px;
                }
            }
        }
    }

    &.navbarDark {
        background-color: #242424;
    }

    img {
        display: block;
        width: 150px;
    }
}
