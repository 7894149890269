.ProgramDate {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    margin: 10px 0 0;
    text-align: left;

    span {
        color: #65184F;
        font-weight: 600;
    }
}