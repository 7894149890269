.ContactsContainer {
    
    h2 {
        font-size: 20px;
        text-align: left;
        margin-bottom: 0;
        margin-top: 22px;
    }

    .ContactsList {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 30px;
        margin: 0;
        padding: 30px 0;

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
            padding: 0 20px;
        }

        .Contact {
            list-style-type: none;
            padding: 20px;
            height: 128px;
            width: 370px;
            border-radius: 7px;
            background-color: #FFFFFF;
            box-shadow: 0 4px 20px -10px rgba(0,0,0,0.25);
            display: flex;
            align-items: center;
            box-sizing: border-box;
        
            div:nth-of-type(2) {
                width: 100%;
            }

            p {
                margin: 0;
                text-align: left;

                &:first-of-type {
                    font-family: 'GeneralSans-Semibold';
                    font-size: 20px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 27px;
                    display: flex;
                    justify-content: space-between;
                }

                &:nth-of-type(2) {
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 18px;
                }
            }

            a:not(.linkedin) {
                margin: 0;
                text-align: left;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 18px;
                display: inherit;
            }


            img.ContactImage {
                height: 88px;
                width: 88px;
                border-radius: 50%;
                margin-right: 15px;
                object-fit: cover;
            }

            img.LogoImage {
                height: 88px;
                width: 88px;
                margin-right: 15px;
                object-fit: contain;
            }
        }
    }

    .Participants {

    }

    .ParticipantsList {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 30px;
        margin: 0;
        padding: 0;
    }

    .Participant {
        display: grid;
        grid-template-columns: 20% 40% 40%;
        background-color: #FFF;
        align-items: center;

        * {
            color: #242424;
        }

        img {
            max-width: 100%;
        }

        .logo {
            padding: 15px;
        }

        .details {
            padding: 15px;
            .name {
                display: flex;
                align-items: center;
                margin: 10px 0;
                
                img {
                    height: 20px;
                    width: 20px;
                    margin-left: 10px;
                }
                
                &:hover {
                    cursor: pointer;
                }
            }

            .member {
                display: flex;
                align-items: center;
                margin: 10px 0;

                svg {
                    height: 20px;
                    width: 20px;
                    margin-left: 10px;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.export {
    font-size: 1.25rem;
    margin: 20px 0;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #242424;
    padding: 10px 20px;
    border-radius: 30px;
    transition: 300ms ease;

    &:hover {
        background-color: #651850;
        border-color: #651850;
        color: white;
    }
}